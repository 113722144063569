
import {defineComponent} from 'vue';
import Loading from '@/components/Loading.vue';
import {mapActions, mapGetters, useStore} from "vuex";

export default defineComponent({
  name: 'PollStartPage',
  components: {
    Loading,
  },
  methods: {
    ...mapActions({
      currentView: 'currentView'
    }),
  },
  computed: {
    ...mapGetters({
      quiz: 'quiz',
      quizIsReady: 'quizIsReady',
      currentView: 'currentView',
    }),
  },

});
