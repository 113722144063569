import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {LoadingState} from "@/models/api/v1/LoadingState";
import TestingFetcher from "@/services/fetch/TestingFetcher";
import * as Sentry from "@sentry/browser";
import {Api} from "@/types/api/v1/result"
import TestResult = Api.V1.Testing.TestResult;

@Module
export default class Result extends VuexModule {
  private _resultLoadingState: LoadingState = LoadingState.Waiting;
  private _resultPageTitle: string = 'Ожидание';
  private _result: undefined | TestResult;

  get result(): undefined | TestResult {
    return this._result;
  }

  get resultLoadingState(): LoadingState {
    return this._resultLoadingState;
  }

  get resultPageTitle(): string {
    return this._resultPageTitle;
  }

  @Mutation
  setResultLoadingState(state: LoadingState): void {
    this._resultLoadingState = state
  }

  @Mutation
  setResult(result: TestResult): void {
    this._result = result
  }

  @Mutation
  setResultPageTitle(title: string): void {
    this._resultPageTitle = title;
  }

  @Action
  loadResult(userPollId: number) {
    let fetcher = new TestingFetcher()
    fetcher.getResult(userPollId).then((response) => {
      if (response.status !== 200) {
        Sentry.captureMessage('Ошибка загрузки результата теста', {
          extra: {
            response: response.data
          }
        })
        this.context.commit('setResultLoadingState', LoadingState.Failed)
        return
      }

      this.context.commit('setResult', response.data);
      this.context.commit('setResultLoadingState', LoadingState.Success)
      this.context.dispatch('watchResultPageTitle').catch()

    }).catch((e) => {
      this.context.commit('setResultLoadingState', LoadingState.Failed)
      this.context.commit('setErrorMessage', e.response.data.message)
      this.context.dispatch('watchResultPageTitle').catch()

    })
  }

  @Action
  watchResultPageTitle() {
    let title = 'Загрузка результата';
    switch (this.resultLoadingState) {
      case LoadingState.Loading:
        title = 'Загрузка результата';
        break;
      case LoadingState.Waiting:
        title = 'Загрузка результата';
        break;
      case LoadingState.Failed:
        title = 'Ошибка';
        break;
      case LoadingState.Success:
        title = this.result?.poll_title || 'Результат опроса';
        break;
    }

    this.context.commit('setResultPageTitle', title);

  }
}