
import {defineComponent, watch} from 'vue';
import {mapActions, mapGetters} from "vuex";
import {useRoute} from "vue-router";
import Loading from "@/components/Loading.vue";
import {LoadingState} from "@/models/api/v1/LoadingState";
import {Api} from "@/types/api/v1/result";
import Variant = Api.V1.Testing.Variant;
import Question = Api.V1.Testing.Question;

export default defineComponent({
  name: 'Result',
  components: {Loading},
  setup() {
    const route = useRoute()

    return {
      // with params.id
      id: route.params.id
    }
  },
  methods: {
    ...mapActions({
      catchError: 'catchError',
      loadResult: 'loadResult',
      resultLoadingState: 'resultLoadingState',
    }),
    ...mapGetters({}),
    getVariantColor(variant: Variant): string {
      switch (variant.variant_correctness_state) {
        case 'danger':
          return 'variant-invalid uk-text-danger uk-text-bold'
        case 'success':
          return 'variant-valid uk-text-success uk-text-bold'
        default:
          return 'test';
      }
    },

    createVerdict(question: Question) {
      let incorrect = 0;
      let notSelected = 0;
      let total = 0;
      question.question_variants.map((value: Variant, index, array) => {
        total++;
        if (value.variant_is_correct && !value.variant_is_selected) {
          notSelected++;
        }

        if (!value.variant_is_correct && value.variant_is_selected) {
          incorrect++;
        }

      })

      if (incorrect == 0 && notSelected == 0) {
        return '<span class="uk-text-success success-message"><span uk-icon="icon: check; ratio:0"></span> Все верно!</span>';
      }

      let messages = [];

      if (incorrect > 0) {
        messages.push('Выбрано ' + incorrect + ' неверных вариантов');
      }

      if (notSelected > 0) {
        messages.push('Выбраны не все верные варианты');
      }

      if (notSelected == total) {
        messages.push('К сожалению ни один из правильных вариантов не был выбран');

      }

      const messageString = messages.join('<br>');

      return `<span class="uk-text-danger error-message"><span uk-icon="icon: ban; ratio:0"></span> ${messageString}</span>`
    },
  },


  computed: {
    LoadingState() {
      return LoadingState
    },
    ...mapGetters({
      resultLoadingState: 'resultLoadingState',
      resultPageTitle: 'resultPageTitle',
      result: 'result',

    }),

  },
  mounted() {
    this.loadResult(this.id);
    window.document.title = this.resultPageTitle;

    watch(() => this.resultPageTitle, () => {
      window.document.title = this.resultPageTitle;
    });

  },


})
