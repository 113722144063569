import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {Component} from "vue"
import QuizView from "@/views/poll/Quiz.vue"
import FailView from "@/views/Fail.vue"
import SuccessView from "@/views/Success.vue"
import SendView from "@/views/Send.vue";
import PollsFetcher from "@/services/fetch/PollsFetcher"
import {LoadingState} from "@/models/api/v1/LoadingState"
import {Api, Poll} from "@/types/api/v1/poll";
import {debounce} from "lodash";
import * as Sentry from "@sentry/browser";
import PollComponent from "@/views/poll/PollStartPage.vue"
import {Feature} from "@/models/api/v1/Feature";
import FetchArgs = Api.V1.FetchArgs;

function DebounceLeading(delay: number) {
  return (target: any, prop: string) => {
    return {
      configurable: true,
      enumerable: false,
      value: debounce(target[prop], delay, {leading: true, trailing: false})
    };
  }
}


@Module
export default class Quiz extends VuexModule {
  private _currentView: Component = PollComponent

  private _loadingState: LoadingState = LoadingState.Waiting

  private _quiz: undefined | Api.V1.Quiz

  private _currentQuestionIndex: number = 0
  private _answers: Array<Poll.Answer> = []

  private _errorMessage: string = ""
  private _isTestable: undefined | boolean;


  get errorMessage(): string {
    return this._errorMessage;
  }


  get currentView(): Component {
    return this._currentView
  }

  get quiz(): undefined | Api.V1.Quiz {
    return this._quiz
  }

  get quizIsReady(): boolean {
    return this._loadingState == LoadingState.Success;
  }

  get quizCurrentQuestionIndex(): number {
    return this._currentQuestionIndex;
  }

  get isTestable() {
    this._isTestable ??= this.quiz?.features.indexOf(Feature.ID_TESTING) !== -1;
    return this._isTestable;
  }

  @Mutation
  setErrorMessage(message: string) {
    this._errorMessage = message;
  }

  @Mutation
  setLoadingState(state: LoadingState): void {
    this._loadingState = state
  }

  @Mutation
  setCurrentView(view: Component): void {
    this._currentView = view
  }

  @Mutation
  setQuiz(quiz: Api.V1.Quiz): void {
    this._quiz = quiz
  }

  @Mutation
  incrementQuestionIndex(): void {
    this._currentQuestionIndex++
  }

  @Mutation
  decrementQuestionIndex(): void {
    this._currentQuestionIndex--
  }

  @Mutation
  resetQuestionIndex(): void {
    this._currentQuestionIndex = 0
  }

  @Mutation
  setAnswers(answers: Array<Poll.Answer>): void {
    this._answers = answers;
  }

  @Mutation
  resetAnswers(): void {
    this._answers = [];
  }

  @Mutation
  addAnswer(answer: Poll.Answer): void {
    this._answers.push(answer);
  }

  //#region Quiz State Actions
  @Action
  startQuiz() {
    let firstQuestion = this._quiz?.questions[0];
    this.context.commit('setQuestion', firstQuestion);

    this.context.commit('setCurrentView', QuizView)
  }

  @Action
  @DebounceLeading(3000)
  doneQuiz() {
    let fetcher = new PollsFetcher()
    try {
      fetcher.checkAuth().then((response) => {
        if (response.status == 200) {
          if (response.data.isAuthenticated) {
            this.context.dispatch('sendQuiz').then((data) => {
            })
          }
          else {
            this.context.commit('setCurrentView', SendView)
          }
        }
      })
    } catch (e) {
      Sentry.captureMessage('Ошибка проверки авторизации', {
        extra: {
          response: e.data
        }
      })
      this.context.commit('setCurrentView', SendView)
    }
  }

  @Action
  showFailPage() {
    this.context.commit('setCurrentView', FailView)
  }

  @Action
  showSuccessPage() {
    this.context.commit('setCurrentView', SuccessView)
  }

  @Action
  resetQuiz() {
    let firstQuestion = this._quiz?.questions[0];
    this.context.commit('setQuestion', firstQuestion);
    this.context.commit('resetQuestionIndex')
    this.context.commit('resetAnswers')

    this.context.commit('setCurrentView', QuizView)
  }

  //#endregion quiz state

  @Action
  loadQuiz(args: FetchArgs) {
    let fetcher = new PollsFetcher()
    fetcher.fetchPoll(args).then((response) => {
      const status = response.status;

      if (status !== 200) {
        Sentry.captureMessage('Ошибка загрузки опроса', {
          extra: {response: response.data}
        })
        this.context.dispatch('showFailPage').catch()

        return
      }

      this.context.commit('setCurrentView', PollComponent);
      this.context.commit('setQuiz', response.data);
      this.context.commit('setLoadingState', LoadingState.Success)

    }).catch((err) => {
      if (err.response?.data?.message) {
        this.context.commit('setErrorMessage', err.response.data.message)
      }

      this.context.commit('setLoadingState', LoadingState.Failed)
      this.context.dispatch('showFailPage')

      fetcher.checkAuth().then((response) => {
        Sentry.captureMessage(err.response.data.message, {
          extra: {
            auth: response.data
          }
        })
      });


    })
  }

  @Action
  nextQuestion() {
    this.context.dispatch('answer').then(() => {
      if (this._quiz && this._currentQuestionIndex == this._quiz.questions_count - 1) {
        this.context.dispatch('doneQuiz').then();
      }
      else {
        this.context.commit('incrementQuestionIndex');
        let nextQuestion = this._quiz?.questions[this._currentQuestionIndex];

        this.context.commit('setQuestion', nextQuestion);
      }
      this.context.commit('setErrorMessage', '')
    }).catch(() => {
      this.context.commit('setErrorMessage', 'Выберите вариант ответа')
    }).finally(() => {
      this.context.commit('setSelectedVariants', undefined);
    });
  }

  @Action
  previousQuestion() {
    let answers = this._answers
    let currentQuestionId = this._quiz?.questions[this._currentQuestionIndex].id

    if (currentQuestionId) {
      answers = deleteAnswer(answers, currentQuestionId)
      this.context.commit('setAnswers', answers)
    }

    this.context.commit('decrementQuestionIndex')

    let previousQuestion = this._quiz?.questions[this._currentQuestionIndex];

    this.context.commit('setQuestion', previousQuestion);
    this.context.commit('setSelectedVariants', null);
    this.context.commit('setErrorMessage', '')
  }
}

function deleteAnswer(answers: Array<Poll.Answer>, questionId: number) {
  let previousAnswer = answers.filter(x => x.question == questionId)[0];
  let previousAnswerIndex = answers.indexOf(previousAnswer);
  if (previousAnswerIndex > -1) {
    answers.splice(previousAnswerIndex, 1)
  }

  return answers;
}